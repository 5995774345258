import React from 'react'
import styled from 'styled-components'

function Section5() {
    return (
        <Container>
            <Wrap>
                <Title>Team</Title>
                <Team>
                    <Profile>
                        <ProfileWrap>
                            <Top>
                                <img src="/assests/Hachiko.png" alt="" />
                            </Top>
                            <Bottom>
                                <Name>Hachiko <a href='https://twitter.com/HachikoSoul' target="_blank" rel="noopener noreferrer"><img src="/assests/twitter.png" alt="" />Follow</a></Name>
                                <Description>
                                    Lead dev with 10+ years of experience leading engineering at an independent game studio in sunny Southern Cali. 
                                </Description>
                            </Bottom>
                        </ProfileWrap>
                    </Profile>
                    <Profile>
                        <ProfileWrap>
                            <Top>
                                <img src="/assests/Laika.png" alt="" />
                            </Top>
                            <Bottom>
                                <Name>Laika <a href='https://twitter.com/LaikaSoul' target="_blank" rel="noopener noreferrer"><img src="/assests/twitter.png" alt="" />Follow</a></Name>
                                <Description>
                                    Biz dev with 12+ years of experience in product development, growth, and partnerships.
                                </Description>
                            </Bottom>
                        </ProfileWrap>
                    </Profile>
                    <Profile>
                        <ProfileWrap>
                            <Top>
                                <img src="/assests/Sly.png" alt="" />
                            </Top>
                            <Bottom>
                                <Name>Sly <a href='https://twitter.com/Sly_doggie' target="_blank" rel="noopener noreferrer"><img src="/assests/twitter.png" alt="" />Follow</a></Name>
                                <Description>
                                    Marketing & Growth lead with 10+ years of experience in digital, product, and growth marketing.
                                </Description>
                            </Bottom>
                        </ProfileWrap>
                    </Profile>
                    <Profile>
                        <ProfileWrap>
                            <Top>
                                <img src="/assests/BRUNO2 .png" alt="" />
                            </Top>
                            <Bottom>
                                <Name>Bruno <a href='https://twitter.com/brunnoalbert' target="_blank" rel="noopener noreferrer"><img src="/assests/twitter.png" alt="" />Follow</a></Name>
                                <Description>
                                    Brazilian-based 3D illustrator, NFT artist, collector, dancer, and dog lover. 
                                </Description>
                            </Bottom>
                        </ProfileWrap>
                    </Profile>
                    <Profile>
                        <ProfileWrap>
                            <Top>
                                <img src="/assests/FILOU 2.png" alt="" />
                            </Top>
                            <Bottom>
                                <Name>Filou <a href='https://twitter.com/SuperNinjaaaa' target="_blank" rel="noopener noreferrer"><img src="/assests/twitter.png" alt="" />Follow</a></Name>
                                <Description>
                                    3D artist based in Paris, a long-time crypto hodler, and one hell of a cool guy.
                                </Description>
                            </Bottom>
                        </ProfileWrap>
                    </Profile>
                </Team>
            </Wrap>
        </Container>
    )
}

export default Section5

const Container = styled.div`
    min-height: 100vh;
    background-image: url('/assests/Section 5.png');
    background-repeat: no-repeat;
    background-size: cover;

`

const Wrap = styled.div`
    padding: 120px 0;
`

const Title = styled.div`
    display: flex;
    justify-content: center;
    font-family: Spicy;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 66px;

    color: #24485B;
`

const Team = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 52px 32px;
`

const Profile = styled.div`
    background: #24485B;
    border-radius: 32px;
    margin: 12px 0;
`

const ProfileWrap = styled.div`
    margin: 0px;
`

const Top = styled.div`
    img {
        max-width: 255px;
        width: 100%;
        max-height: 255px;
        height: 100%;
        border: 3.56633px solid #24485B;
        box-sizing: border-box;
        border-radius: 32px 32px 0px 0px;
    }
`

const Bottom = styled.div`
    margin: 6px 12px;
`

const Name = styled.div`
    margin: 4px 0;
    display: flex;
    justify-content: space-between;
    font-family: Spicy;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    /* identical to box height */


    color: #FFFFFF;
    a {
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;
        border: none;
        outline-width: 0px;
        background: #5893B7;
        border-radius: 46.0141px;
        padding: 7.43662px 14.8732px 7.43662px 13.9437px;
        font-style: normal;
        font-weight: 600;
        font-size: 14.8732px;
        line-height: 18px;
        /* identical to box height */


        color: #FFFFFF;

        img {
            width: 12px;
            margin: 0px 9.29578px;
            height: 11px;
            object-fit: cover;
        }
    }
`

const Description = styled.div`
    max-width: 231px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 14.8732px;
    line-height: 18px;

    color: #FFFFFF;
    padding: 12px 0;
`