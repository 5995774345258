import React from 'react'
import styled from 'styled-components'
import Footer from '../components/sections/Footer'
import Section2 from '../components/sections/Section2'
import Section3 from '../components/sections/Section3'
import Section4 from '../components/sections/Section4'
import Section5 from '../components/sections/Section5'
import Section6 from '../components/sections/Section6'
import Starter from '../components/sections/Starter'

function Home() {
    return (
        <Container className='home'>
            <Section id='starter'>
                <Starter />
            </Section>
            <Section id="about">
                <Section2 />
            </Section>
            <Section id='rarity'>
                <Section3 />
            </Section>
            <Section id='roadmap'>
                <Section4 />
            </Section>
            <Section id='team'>
                <Section5 />
            </Section>
            <Section id='faq'>
                <Section6 />
            </Section>
            <Footer />
        </Container>
    )
}

export default Home

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const Section = styled.section`

`