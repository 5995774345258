import React, { useEffect } from 'react'
import styled from 'styled-components'
import Accordion from '../Accordion'


function Section6() {

    return (
        <Container>
            <Wrap>
                <Title>Say What?</Title>
                <QNA>
                    <QNAWrap>
                        <Accordion title='What’s an NFT?' desc="Non-fungible tokens or NFTs are a unique and non-interchangeable unit of data stored on the Blockchain. These smart-contract powered tokens can be used to represent ownership of unique items because they can only have one official owner at a time no one can modify the record of ownership or copy/paste a new NFT into existence." first={true}  />
                        <Accordion title="I'm new to Solana, how do I buy a Soul Dogs NFT?" desc="Welcome, we 💜  Solana because it's fast, secure, scalable, and  has much lower transaction fees compared to Ethereum. To get set up on Solana you will need set-up a Solana-based wallet, we recommend <a href='https://phantom.app/'  target='_blank' rel='noopener noreferrer' >Phantom</a>. Having a Phantom wallet gives you access to decentralised apps and a Solana address where you NFT will be stored." />
                        <Accordion title="What will be the price to mint a Soul Dog?" desc='The mint price has yet to be announced, join our <a href="http://discord.gg/souldogs" target="_blank" rel="noopener noreferrer">Discord</a> or follow us on <a href="https://twitter.com/SoulDogsNFT" target="_blank" rel="noopener noreferrer">Twitter</a> for updates' />
                        <Accordion title="Will there be a pre-sale? " desc="We will conduct a pre-sale to reward early supporters with guaranteed access to mint up to 2 Soul Dogs before the public sale. You must have a Soul Dogs Mintpass to participate in pre-sale." />
                        <Accordion title="How do I earn a Soul Dogs Mintpass?" desc="The first 1000 Discord members will automatically become OG Dogs. The remaining 500 Mintpasses can be earned via giveaways, participating in our invite contest, or by leveling up as an active community member. More details on how to earn a mintpass are available in our <a href='http://discord.gg/souldogs' target='_blank' rel='noopener noreferrer'>Discord</a>" />
                        <Accordion title='Who’s the team behind Soul Dogs?' desc="The Soul Dog Crew is a group of talented artists and devs who all want to see a more little soul brought to the Metaverse. Our team has deep experience in game development, engineering, product marketing, and 3D design and animation. You'll learn more about our team in our weekly open community AMAs." last={true} />
                    </QNAWrap>
                </QNA>
            </Wrap>
        </Container>
    )
}

export default Section6

const Container = styled.div`
    min-height: 120vh;
    background-image: url('/assests/Section 6.png');
    background-repeat: no-repeat;
    background-size: cover;
`

const Wrap = styled.div`
    /* margin: 16px; */
    /* height: 60px;
    background-color: khaki;
    width: 600px;
    box-shadow: 10px 10px;
    border-radius: 20px; */

`

const Title = styled.div`
    margin: 0 64px;
    padding: 64px 0;
    display: flex;
    justify-content: center;
    font-family: Spicy;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 66px;

    color: #24485B;
`

const QNA = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 64px;  

`

const QNAWrap = styled.div`
    margin: 0 6px;
    max-width: 1130px;
    width: 100%;
    border: 3px solid #24485B;
    box-shadow: 12px 12px 0px 6px #24485B;
    border-radius: 20px;


    .first {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .last {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .TRUE {
    }
`

