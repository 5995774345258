import React from "react";
import styled from "styled-components";

function Footer() {
  return (
    <Container>
      <Wrap>
        <a href="/">
          <img className="logo" src="/assests/logo.png" alt="" />
        </a>
        <div className="title">Retro-Inspired 3D NFTs with Soul.</div>
        <ButtonWrap>
          <a
            className="discord"
            href="http://discord.gg/souldogs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assests/discord.png" alt="discord" />
            Discord
          </a>
          <a
            className="twitter"
            href="https://twitter.com/SoulDogsNFT"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assests/twitter.png" alt="twitter" />
            Twitter
          </a>
        </ButtonWrap>

        <Privacy>@2021 Soul Dogs. All rights reserved.</Privacy>
      </Wrap>
    </Container>
  );
}

export default Footer;

const Container = styled.div`
  background-color: #fff9f0;
`;

const Wrap = styled.div`
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logo {
    max-width: 276px;
    width: 100%;
    max-height: 267px;
    object-fit: cover;
  }

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #24485b;
    /* margin: 6px 0; */
  }
`;

const ButtonWrap = styled.div`
  margin: 16px 0;
  display: flex;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  .discord {
    background: #8187c9;
    box-shadow: 5px 5px #262b59;
    border: 2.13162px solid #262b59;
  }

  .twitter {
    background: #5893b7;
    box-shadow: 5px 5px #24485b;
    border: 2.13162px solid #24485b;
  }

  a {
    text-decoration: none;
    margin: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 11.3687px 22.7373px 11.3687px 21.3162px;
    width: 171.24px;
    height: 50.45px;
    left: 536.76px;
    top: 6366px;
    box-sizing: border-box;
    border-radius: 70.3435px;
    font-weight: 600;
    font-size: 22.7373px;
    line-height: 28px;
    color: #ffffff;

    img {
      width: 21px;
      height: 16px;
      object-fit: cover;
    }
  }
`;

const Privacy = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 111.5%;
  /* identical to box height, or 27px */

  text-align: center;

  color: #24485b;
`;
