import React from 'react'
import styled from "styled-components"

const Img = ({ img }) => (
    <ImgWrap >
        <img className='cover' src="assests/cover.png" alt="" />
        <Cover>
            <img className='main_img' src={`assests/${img}`} alt="" />
        </Cover>
    </ImgWrap> 
)

function Section2() {
    return (
        <Container>
            {/* <img width="100%" height="auto" src="assests/waves 1.png" alt="" /> */}
            <Content>
                <Wrap>
                    <Title>What are Soul Dogs?</Title>
                    <Description>
                        <DesWrap>
                            Soul Dogs are a collection of 10,000 randomly generated misfits, rebels, dreamers, and troublemakers living on the Solana Blockchain. Inspired by retro art, music, fashion, and cinema - Soul Dogs perfectly blend fun, funky vibes with high-quality 3D art to embody a new cultural movement. 
                        </DesWrap>
                    </Description>
                    <ImageSection>
                        <Img img={'DISCO 1.png'} />
                        <Img img={'PEACE 1.png'} />
                        <Img img={'ROCK 1.png'} />
                        {/* <Img img={'GRAND MA 1.png'} /> */}
                        <Img img={'ASTRONAUT 1.png'} />
                    </ImageSection>
                    <CountSection>
                        <Counter>
                            <Name>Soul Dogs</Name>
                            <Count>10,000</Count>
                        </Counter>
                        <Counter>
                            <Name>Traits</Name>
                            <Count>165</Count>
                        </Counter>
                        <Counter>
                            <Name>Combinations</Name>
                            <Count>300,000+</Count>
                        </Counter>
                    </CountSection>
                </Wrap>
            </Content>
        </Container>
    )
}

export default Section2


const Container = styled.div`
    position: relative;
    background-image: url('/assests/Section 2.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 140vh;
`

const Content = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;

`

const Wrap = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    flex: 1;
    @media(max-width: 1260px){
        margin-top: 32px;
    }
`

const Title = styled.h1`
    display: flex;
    justify-content: center;
    font-family: spicy !important;
    font-style: normal;
    font-weight: 1000;
    font-size: 54px;
    line-height: 66px;

    color: #24485B;
    @media(max-width: 960px) {
        font-size: 32px;
    }

`

const Description = styled.p`
    margin: 32px 0;
    display: flex;
    justify-content: center;
    @media(max-width: 1140px){
        margin: 6px 0;
    }
`

const DesWrap = styled.p`
    width: 80%;
    /* font-family: Montserrat; */
    font-size: 28px;
    line-height: 29px;
    @media(max-width: 960px) {
        font-size: 20px;
    }
`

const ImageSection = styled.div`
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin: 56px 32px; 
    @media(max-width: 1430px) {
        margin: 32px 32px; 
    
    }
    @media(max-width: 500px) {
        margin: 32px 6px;
    }
`

const ImgWrap = styled.div`
    position: relative;
    margin: 0 16px;
    img {
        width: 222px;
        height: 222px;
    }
    .cover {
        transform: translate(16px, 16px);
        width: 222px;
        height: 222px;
    }
    @media(max-width: 1100px){
        img {
            width: 160px;
            height: 160px;
        }
        .cover {
            width: 160px;
            height: 160px;
        }
    }
    @media(max-width: 840px) {
        margin: 10px 8px;
    
    }
    @media(max-width: 500px) {
        .cover {
            transform: translate(0px, 0px);
            transform: scale(0);
        }
    }
    @media(max-width: 390px) {
        img {
            width: 120px;
            height: 120px;
        }
        .cover {
            width: 120px;
            height: 120px;
        }
    }
`

const Cover = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    .main_img {
        border: 3px solid #24485B;
        box-sizing: border-box;
        border-radius: 34.441px;
    }
`

const CountSection = styled.div`
    margin: 56px 32px; 
    display: flex;
    justify-content: space-evenly;
    @media(max-width: 1430px) {
        margin: 0 32px;
    }
    @media(max-width: 960px) {
        box-shadow: 0px 0px 10px;
        background-color: #FFF1DB;
        border: 3px solid #24485B;
        border-radius: 20px;
    }
    @media(max-width: 500px) {
        margin: 56px 6px; 
        
    }
`

const Counter = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width: 960px) {
        padding: 16px;
    }
    @media(max-width: 960px) {
        padding: 16px;
    }
    @media(max-width: 500px) {
        padding: 6px;
    }
`

const Name = styled.h1`
    font-family: spicy;
    font-size: 52px;
    line-height: 66px;
    font-weight: 1000;
    color: #24485B;
    @media(max-width: 1000px){
        font-size: 42px;
    }
    @media(max-width: 960px) {
        line-height: 32px;
        font-size: 24px;
    }
    @media(max-width: 590px) {
        font-size: 18px;
    }
`

const Count = styled.div`
    margin-top: 26px;
    font-size: 52px;
    color: #24485B;
    font-weight: 500;
    @media(max-width: 1000px){
        font-size: 32px;
        margin-top: 0px;
    }
    @media(max-width: 960px) {
        font-size: 26px;
    }
    @media(max-width: 590px) {
        font-size: 16px;
    }
`