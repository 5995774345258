import { Clear, Dehaze } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'

function Navbar() {

    const [active, setActive] = React.useState(false)

    return (
        <Container>
            <Wrap>
                <a href="/"><Logo src='/assests/logo.png' /></a>
                <RightSection active={active}>
                    <ButtonWrap onClick={()=>setActive(false)}>
                        <Clear />
                    </ButtonWrap>
                    <Content>
                        <li><a href="#about">About</a></li>
                        <img src="/assests/Vector.png" alt="" />
                        <li><a href="#rarity">Rarity</a></li>
                        <img src="/assests/Vector.png" alt="" />
                        <li><a href="#roadmap">RoadMap</a></li>
                        <img src="/assests/Vector.png" alt="" />
                        <li><a href="#team">Team</a></li>
                        <img src="/assests/Vector.png" alt="" />
                        <li><a href="#faq">Faq</a></li>
                    </Content>

                </RightSection>
                <ExtraWrap onClick={()=>setActive(true)}>
                    <Dehaze />
                </ExtraWrap>
            </Wrap>
        </Container>
    )
}

export default Navbar


const Container = styled.div`

    margin: 0 32px;
    @media(max-width: 1120px) {
        margin: 0px;
    }
`

const Wrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    
    .MuiSvgIcon-root {
        display: none;
        font-size: 3.0em;
    }

    @media(max-width: 1120px) {
        
        .MuiSvgIcon-root {
            display: flex;
            margin-right: 32px;
        }
    }

    @media(max-width: 450px) {
        .MuiSvgIcon-root {
            margin-right: 16px;
        }
    }
`


const Logo = styled.img`
    width: 280px;
    @media(max-width: 400px) {
        margin-left: -40px;
    }
`   

const RightSection = styled.div`
    display: flex;

    @media(max-width: 1120px) {
        display: flex;
        flex-direction: column;
        transform: ${props => props.active ? 'translateX(0)' : 'translateX(100%)'};
        transition: transform 0.5s ease-in-out;
        position: fixed;
        z-index: 10;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 24px;
        background-color: #FFF1DB;
        border: 3px solid #24485B;
    }

`

const Content = styled.div`
    display: flex;
    padding: 16px;
    background-color: #FFF1DB;
    border: 3px solid #24485B;
    box-sizing: border-box;
    border-radius: 24px;
    align-items: center;

    li {
        a {
            font-family: spicy;
            font-style: normal;
            font-weight: normal;
            font-size: 30px;
            line-height: 44px;
            font-weight: 800;
            color: #24485B;
            text-transform: uppercase;
        }
        list-style: none;
        margin: 0px 24px;        
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    img {
        width: 30px;
        height: 30px;
        margin: 0px 24px;
    }

    @media(max-width: 1370px) {
        li {
            margin: 0px 10px;        
        }
    }
    @media(max-width: 1250px) {
        li {
            margin: 0px 6px;        
        }
        img {
            margin: 0px 6px;        
        }
    }
    .MuiSvgIcon-root {
        display: none;
    }
    @media(max-width: 1120px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: none;
        border: none;
        padding: 6px 16px;
        li {
            padding: 6px 26px;
            margin: 6px 0;
        }

        img {
            display: none;
        }
        
    }
`

const ButtonWrap = styled.div`
    @media(max-width: 1120px) {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        .MuiSvgIcon-root {
            margin-top: 32px;
            margin-right: 32px;
        }
    }
`

const ExtraWrap = styled.div`
    display: none;
    @media(max-width: 1120px) {
        display: flex;
    }
`

const Extra = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    background: #74CACB;
    border: 3px solid #24485B;
    box-sizing: border-box;
    border-radius: 24px;
    font-family: Spicy;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 44px;
    color: #24485B;
    font-weight: 800;
    margin: 0 56px;

    li {
        list-style: none;
    }
    a {
        color: inherit;
        text-decoration: none;
    }
    @media(max-width: 1120px) {
        padding: 0;
        margin: 0;
        width: 100%;
    }
`