import React from 'react'
import styled from 'styled-components'
import Navbar from '../Navbar/Navbar'

function Starter() {
    return (
        <Container>
            <Bg>
                {/* <img src="/assests/waves.png" alt="" /> */}
            </Bg>
            <Wrap>
                <Navbar />
                <Content>
                    <LeftContent>
                        <Box />
                        <SubContent>
                            <Title>A retro-inspired 3D Metaverse <span>with Soul</span></Title>
                            {/* <p>10,000 Retro-Inspired 3D NFTs with Soul</p> */}
                            <Button>
                                <DiscordButton href="http://discord.gg/souldogs" target="_blank" rel="noopener noreferrer"><img src="/assests/discord.png" alt="discord" /><span>Discord</span></DiscordButton>
                                <TwitterButton href="https://twitter.com/SoulDogsNFT" target="_blank" rel="noopener noreferrer"><img src="/assests/twitter.png" alt="twitter" /><span>Twitter</span></TwitterButton>
                            </Button>
                        </SubContent>
                    </LeftContent>
                    <RightContent>
                        <ImgWrap><img src="/assests/dog_gif.gif" alt="" /></ImgWrap>
                    </RightContent>
                </Content>
            </Wrap>
        </Container>
    )
}

export default Starter

const Container = styled.div`
    min-height: 130vh;
    background-color: #fff9f0;
    position: relative;
    background-image: url('/assests/waves.png');
    background-size: cover;
    background-repeat: no-repeat;
`

const Bg = styled.div`
    position: absolute;
    height: inherit;
    width: inherit;
`

const Wrap = styled.div`
    position: relative;
`


const Content = styled.div`
    display: flex;
    margin: 64px 160px;
    align-items: center;
    justify-content: space-evenly;
    
    @media(max-width: 1290px) {
        // margin: 0px 64px;
    }
    @media(max-width: 987px) {
        flex-direction: column;
        justify-content: center;
    }
    @media(max-width: 611px) {
        margin: 32px 10px;
    }
`

const LeftContent = styled.div`
    position: relative;
    // z-index: 100;
    min-width: 729px;
    height: 455px;

    @media(max-width: 1190px) {
        min-width: 629px;
        
    }
    @media(max-width: 1190px) {
        min-width: 549px;
    }
    @media(max-width: 987px) {
        max-width: 500px;
        width: 100%;
        margin-bottom: 64px;
    }
    @media(max-width: 611px) {
        max-width: 400px;
        min-width: 200px;

    }
    @media(max-width: 400px) {
        height: 360px;
    }
`

const SubContent = styled.div`
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: space-evenly;
    // justify-content: center;
    padding: 32px 40px;

    position: absolute;
    max-width: 649px;
    width: 100%;
    height: 441px;

    background: #FFF1DB;
    border: 3px solid #24485B;
    box-sizing: border-box;
    border-radius: 40px;

    p {
        margin: 16px 0;
        font-size: 1.5em;
    }
    @media(max-width: 1190px) {
        max-width: 489px;
        height: 420px;

    }
    @media(max-width: 987px) {
        max-width: 689px;  
        width: 100%;  
    }
    @media(max-width: 611px) {
        width: 100%;  
        max-width: 400px;
        min-width: 200px;
    }
    @media(max-width: 400px) {
        height: 360px;
    }
    
`

const Title = styled.div`
    font-family: spicy;
    font-style: normal;
    font-weight: 800;
    font-size: 62px;
    line-height: 70px;
    /* or 109% */
    letter-spacing: 0.045em;
    text-transform: uppercase;

    color: #24485B;

    span {
        font-family: spicy;
        letter-spacing: 0.045em;
        color: #D23032;
    }
    @media(max-width: 1190px) {
        font-size: 42px;  
    }
    @media(max-width: 611px) {
        font-size: 40px;  

    }
    @media(max-width: 516px) {
        font-size: 38px;  
        line-height: 42px;
        
    }
    @media(max-width: 300px) {
        font-size: 36px;
    }
`

const Button = styled.div`
    display: flex;
    margin: 16px 0;
    
`

const DiscordButton = styled.a`
    background: #8187C9;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px 16px 30px;
    border: 3px solid #262B59;
    box-sizing: border-box;
    border-radius: 99px;
    width: 201px;
    color: white;
    font-weight: 600;
    font-size: 26px;
    box-shadow: 4px 4px 1px black; 
    cursor: pointer;
    text-decoration: none;
    img {
        margin-right: 16px;
    }
    @media(max-width: 611px) {
        img {
            margin-right: 0px;
            width: 40px;
        }
        span {
            display: none;
        }
    }
    @media(max-width: 330px) {
        width: 90px;
    }
`

const TwitterButton = styled.a`
    background: #5893B7;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    align-items: center;
    padding: 16px 32px 16px 30px;
    border: 3px solid #262B59;
    box-sizing: border-box;
    border-radius: 99px;
    width: 201px;
    color: white;
    font-weight: 600;
    font-size: 26px;
    box-shadow: 4px 4px 1px black; 
    cursor: pointer;
    margin-left: 32px;
    img {
        margin-right: 16px;
    }

    @media(max-width: 611px) {
        img {
            margin-right: 0px;
            width: 40px;
        }
        span {
            display: none;
        }
    }
    @media(max-width: 330px) {
        width: 90px;
    }
`

const Box = styled.div`

    position: absolute;
    max-width: 649px;
    width: 100%;
    height: 441px;
    margin-left: 20px;
    margin-top: 20px;

    background: #D23032;
    border: 3px solid #24485B;
    box-sizing: border-box;
    border-radius: 40px;
    @media(max-width: 1190px) {
        max-width: 489px;
        height: 420px;

    }
    @media(max-width: 987px) {
        max-width: 689px;    
    }
    @media(max-width: 987px) {
        margin-left: 20px;
        margin-top: 20px;       
    }
    @media(max-width: 611px) {
        max-width: 400px;
        min-width: 200px;
    }
    @media(max-width: 400px) {
        // display: none;   
        margin-left: 0px;
        height: 360px;
    }
    
`

const RightContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const ImgWrap = styled.div`

    img {
        height: 451px;
        background: #24485B;
        width: auto;
        object-fit: cover;
        border: 3.5px solid #24485B;
        // box-sizing: border-box;
        // padding-left: -15px;
        border-radius: 47.551px;
    }
    @media(max-width: 1190px) {
        img {
            height: 420px;
        }
    }
    @media(max-width: 611px) {
        img {
            max-width: 400px;
            min-width: 200px;
        }
    }
    @media(max-width: 400px) {
        img {
            height: 300px;
            object-fit: contain;

        }
    }
`