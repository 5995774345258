import React from 'react'
import styled from 'styled-components'
import Fade from "react-reveal"

function Section4() {
    
    const [scrollElement, setScrollElement] = React.useState({
        Phase1: "0%",
        Phase2: '0%',
        Phase3: '0%',
        Phase4: '0%',

    })

    const scrollUpdate = () => {
        let current = ""
        const sections = document.querySelectorAll('section')
        const Progress = document.getElementById('Progress')
        const { pageYOffset } = window;
        const progressTop = Progress.offsetTop;
        const progressheight = Progress.offsetHeight;
        sections.forEach( section => {
            const sectionTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (pageYOffset >= (sectionTop - sectionHeight )) {
                current = section.getAttribute('id')
                if (current) {
                    const sec = document.getElementById(current)
                    const secTop = (sec.offsetTop)
                    const secHeight = (sec.offsetHeight)
                    let value = (Math.round((window.scrollY+250) - sec.offsetTop))
                    // console.log(current, value)
                    // console.log((pageYOffset/(sectionTop-sectionHeight/6)))
                    const updatedValue = {}
                    
                    if (value < 100 && value > 0) {
                        value = (value.toString() + "%")
                    } 
                    else if (value > 100) {
                        value = "100%"
                    } else {
                        value = "0%"
                    }
                    setScrollElement(prev => ({ ...prev, [current]: value}))
                }
            }
            else if(pageYOffset <= (progressTop - progressheight))  {
                setScrollElement((prev => ({ ...prev, Phase1: "0%", Phase2: "0%", Phase3: "0%", Phase4: "0%"})))
            }
        } )
    }

    React.useEffect(() => {
        scrollUpdate();
        window.addEventListener('scroll', scrollUpdate)
        return () => {
            window.removeEventListener('scroll', scrollUpdate)
        }
    }, [])
    // console.log(scrollElement)

    return (
        <Container>
            <Wrap>
                <Title>Vision</Title>
                <Description>Soul Dogs was born out of a desire to inspire people to do more, create more, and explore new digital worlds. The Soul Dogs City metaverse will become a gateway to the decentralised world and a place where people come to express themselves, have meaningful experiences, and become a part of a vibrant and diverse community.</Description>
                <Title>Roadmap</Title>
                <Description>Creating the Soul Dogs City 3D metaverse is an ambitious project that we've broken into three phases. To kick things off we're introducing the Soul Dogs collection in phase 1 to build the foundations of our metaverse and community. In the second phase we will introduce a genesis collection of digital land and utility in the form of staking and yield farming. Phase 3 focuses on expanding the voices in Soul Dogs City through DAO governance, customisation, and companion collections. </Description>
                <ProgressWrap id='Progress'>
                    {/* <Fade bottom> */}
                        <Phase id='Phase1'>
                            <Left>
                                <Box height={scrollElement.Phase1}>
                                    <Rect height={scrollElement.Phase1}>
                                        <img src="/assests/DogVector.png" alt="" />
                                    </Rect>
                                </Box>
                                <Stick>
                                    <Progress height={scrollElement.Phase1} />
                                </Stick>
                            </Left>
                            <RightWrap>
                                <Right height={scrollElement.Phase1}>
                                    <Name height={scrollElement.Phase1}>Phase 1 - Introducing Soul Dogs</Name>
                                    <Desc height={scrollElement.Phase1}>Assemble Soul Dog Wrecking Crew</Desc>
                                    <div className='content'>
                                        To manifest our vision, we first needed to gather an awesome crew. The Soul Dog Crew is a group of talented artists and devs who all want to see a more little soul brought to the Metaverse. Our team has deep experience in game development, engineering, product marketing, and 3D design and animation.
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase1}>
                                    {/* <Name height={scrollElement.Phase1}>Phase 1 - Introducing Soul Dogs</Name> */}
                                    <Desc height={scrollElement.Phase1}>1/1 Giveaways & Charity Auctions</Desc>
                                    <div className='content'>
                                        Before and after launch we’ll conduct giveaways and an auction for 1/1 custom Soul Dogs (outside of the main 10,000 piece collection) with all proceeds donated to a charity chosen by the community.
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase1}>
                                    {/* <Name height={scrollElement.Phase1}>Phase 1 - Introducing Soul Dogs</Name> */}
                                    <Desc height={scrollElement.Phase1}>Mintpass & Pre-sale</Desc>
                                    <div className='content'>
                                        We will conduct a pre-sale to reward early supporters with guaranteed access to mint a Soul Dog before the public sale.
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase1}>
                                    {/* <Name height={scrollElement.Phase1}>Phase 1 - Introducing Soul Dogs</Name> */}
                                    <Desc height={scrollElement.Phase1}>Let Soul Dogs out on Mainnet</Desc>
                                    <div className='content'>
                                        Public sale date and mechanics will be announced closer to launch, but we're targeting end-November/early-December and want to make it as fair to everyone as possible.
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase1}>
                                    {/* <Name height={scrollElement.Phase1}>Phase 1 - Introducing Soul Dogs</Name> */}
                                    <Desc height={scrollElement.Phase1}>Premier Marketplace Partnership</Desc>
                                    <div className='content'>
                                        Launch the Soul Dogs collection on the most popular Solana marketplaces after the Public Sale.
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase1}>
                                    {/* <Name height={scrollElement.Phase1}>Phase 1 - Introducing Soul Dogs</Name> */}
                                    <Desc height={scrollElement.Phase1}>Rarity & Sales Tools</Desc>
                                    <div className='content'>
                                        Simple and easy to use tools to track sales and check the rarity for your Soul Dog that will never have you thinking "looks rare" again.
                                    </div>
                                </Right>
                            </RightWrap>
                        </Phase>
                        <Phase id='Phase2'>
                            <Left>
                                <Box height={scrollElement.Phase2}>
                                    <Rect height={scrollElement.Phase2}>
                                        <img className='home' src="/assests/HomeVector.png" alt="" />
                                    </Rect>
                                </Box>
                                <Stick>
                                    <Progress height={scrollElement.Phase2} />
                                </Stick>
                            </Left>
                            <RightWrap>
                                <Right height={scrollElement.Phase2}>
                                    <Name height={scrollElement.Phase2}>Phase 2 - The Soul Dogs City Metaverse</Name>
                                    <Desc height={scrollElement.Phase2}>3D Dog Houses - Genesis Drop</Desc>
                                    <div className='content'>
                                        We're breaking ground on the Soul Dog City 3D Metaverse by dropping a genesis collection of 3D Dog Houses. Each Dog House will have a unique address and you'll be able to visit and explore all of Soul Dogs City through our 3D Web Portal.
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase2}>
                                    <Desc height={scrollElement.Phase2}>$BONES Utility Token & Staking</Desc>
                                    <div className='content'>
                                        $BONES is the native utility token of the Soul Dogs City Metaverse and will be used to customize Soul Dogs, Dog Houses, and for future DAO voting rights.
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase2}>
                                    <Desc height={scrollElement.Phase2}>Soul Dogs City 3D Web Portal</Desc>
                                    <div className='content'>
                                        An immersive, interactive web experience that owners and visitors explore every corner of Soul Dogs City.
                                    </div>
                                </Right>
                            </RightWrap>
                        </Phase>
                        <Phase id='Phase3'>
                            <Left>
                                <Box height={scrollElement.Phase3}>
                                    <Rect height={scrollElement.Phase3}>
                                        <img src="/assests/Bone.png" alt="" />
                                    </Rect>
                                </Box>
                                <Stick>
                                    <Progress height={scrollElement.Phase3} />
                                </Stick>
                            </Left>
                            <RightWrap>
                                <Right height={scrollElement.Phase3}>
                                    <Name height={scrollElement.Phase3}>Phase 3 - City Council Leadership & Expansion</Name>
                                    <Desc height={scrollElement.Phase3}>We launch the infrastructure to govern and expand Soul Dog City</Desc>
                                    <div className='content'>
                                        {/* To manifest our vision, we first needed to gather an awesome crew. The Soul Dog Crew is a group of talented artists and devs who all want to see a more little soul brought to the Metaverse. */}
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase3}>
                                    <Desc height={scrollElement.Phase3}>DoggieDAO</Desc>
                                    <div className='content'>
                                        Launch DAO infrastructure to allow $BONES holders to vote on proposals such as the sale of new Dog Houses, the use of treasury funds, and general project governance. 
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase3}>
                                    <Desc height={scrollElement.Phase3}>City Council Elections</Desc>
                                    <div className='content'>
                                        Open DAO vote for leadership roles within Soul Dogs City, the city needs a Mayor and Alpha Dogs of DoggieDAO
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase3}>
                                    <Desc height={scrollElement.Phase3}>Soul Dogs City Shop</Desc>
                                    <div className='content'>
                                        Tools to allow the community to create in the Soul Dogs City metaverse, and a marketplace to spend $BONES to customize Soul Dogs and Dog Houses.
                                    </div>
                                </Right>
                                <Right height={scrollElement.Phase3}>
                                    <Desc height={scrollElement.Phase3}>Soul Companions </Desc>
                                    <div className='content'>
                                        Introducing new characters in Soul Dog City through collaboration, partnerships, and new drops!
                                    </div>
                                </Right>
                            </RightWrap>
                        </Phase>
                        
                    {/* </Fade> */}
                </ProgressWrap>
            </Wrap>
        </Container>
    )
}

export default Section4

const Container = styled.section`
    min-height: 100vh;
    background-image: url('/assests/Section 4.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-position: cover; */
    // min-width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
`   


const Wrap = styled.div`
    margin: 64px 32px; 
    // margin-top: 64px;
    display:flex;
    flex-direction: column;
    // align-items: flex-start;
    max-width: 951px;
    width: 100%;
    min-width: 260px;
`

const Title = styled.div`
    font-family: Spicy;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 66px;

    color: #24485B;

    /* identical to box height, or 36px */
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 16px 0;
`

const Description = styled.p`

    // max-width: 951px;
    // // max-height: 88px;
    // width: 100%;
    margin: 0 16px;
    height: 100%;
    // min-width: 200px;
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 111.5%;
    /* or 22px */

    text-align: justify;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
`

const ProgressWrap = styled.div`
    margin: 32px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`

const Phase = styled.section`
    display: flex;
    max-width: 951px;
    width: 100%;
`

const Left = styled.div`
    /* margin-top: 32px; */
    margin-right: 32px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    @media(max-width: 600px) {
        display: none;
    }
`

const Box = styled.div`
    width: 50px;
    height: 50px;
    /* border: ${props => (props.height !== "0%" ) ? '3px solid #24485B': "3px solid #FFFFFF66"}; */
    background-color: ${props => (props.height !== "0%" ) ? '#24485B' : '#FFFFFF'};
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
`

const Rect = styled.div`
    width: 35px;
    height: 35px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${props => (props.height !== "0%" ) ? '1px solid #FFFFFF66': "1px solid #24485B"};

    img {
        width: 30px;
        height: 30px;
        object-fit: contain;
    }

    .home {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
    background:  ${props => (props.height !== "0%" ) ? '#FFFFFF' : '#FFFFFF66'};
`

const Stick = styled.div`
    /* margin-top: 16px; */
    position: relative;
    width: 10px;
    height: -webkit-fill-available;
    background: rgba(255, 255, 255, 0.25);
`

const Progress = styled.div`
    position: absolute;
    width: 100%;
    height: ${props => props.height};
    // height: 60%;
    background: #24485B;
`

const Right = styled.div`
    /* padding: 12px 16px;     */
    margin: 6px 0;
    height: fit-content;    
    max-width: inherit;
    width: 100%;
    /* border: 3px solid ${props => props.height !== "0%" ? '#ffffff': '#ffffff66'} ; */
    background: #24485B;
    border-radius: 24px;

    .content {
        /* font-family: Montserrat; */
        /* margin: 6px 0; */
        margin: 6px 16px;    
        padding-bottom: 16px;
        font-style: normal;
        font-weight: 100;
        font-size: 20px;
        line-height: 29px;

        color: #F2EDE3;
    }

`

const RightWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
    margin: 0 16px;
`

const Name = styled.div`
    margin: 12px 16px;    

    font-family: Spicy;
    font-style: normal;
    font-weight: normal;
    font-size: 36px;
    /* line-height: 50px; */
    color: #24485B;
    padding: 7px 21px;
    background: #F2EDE3;
    border-radius: 16px;
    color: ${props => props.height !== '0%' ? '#24485B;' : '#24485b47'};

    @media(max-width: 450px) {
        font-size: 28px;
    }
`

const Desc = styled.div`
    margin: 12px 16px;    
    margin-top: 20px;
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: ${props => props.height !== '0%' ? '#FFFFFF': '#FFFFFF66'};

    @media(max-width: 450px) {
        font-size: 20px;
    }
`

const Content = styled.div`
    margin: 12px 16px;    
    margin: 24px 0;
    max-width: 702px;
    width: 100%;
    min-width: 100px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    color: ${props => props.height !== '0%' ? '#FFFFFF': '#FFFFFF66'};

    @media(max-width: 450px) {
        font-size: 18px;
    }
`

