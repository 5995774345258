import React, { useEffect } from 'react'
import styled from 'styled-components'
import common from '../../Common.png'
import cool from '../../Cool.png'
import dynomite from '../../Dynomite.png'
import fly from '../../Fly.png'
import groovy from '../../Groovy.png'

const list = {
    'COMMON': common,
    'COOL': cool,
    'DYNOMITE': dynomite,
    'FLY': fly,
    'GROOVY': groovy,
}

const newList = {
    'COMMON': {
        X: '0%',
        Y: '0%',
        Z: '0deg'
    },
    'COOL': {
        X: '6%',
        Y: '-78%',
        Z: '49deg'
    },
    'GROOVY': {
        X: '18%',
        Y: '-96%',
        Z: '82deg'
    },
    'FLY': {
        X: '32%',
        Y: '-85%',
        Z: '114deg'
    },
    'DYNOMITE': {
        X: '46%',
        Y: '-34%',
        Z: '151deg'
    },
}

const ButtonList = [
    {
        name: 'COMMON',
        X:'-28%',
        Y: '-30%',
        Z: '0deg'
    },
    {
        name: 'Star'
    },
    {
        name: 'COOL',
        X:'-15%',
        Y: '-230%',
        Z: '54deg'
    },
    {
        name: 'Star'
    },
    {
        name: 'GROOVY',
        X:'1%',
        Y: '-308%',
        Z: '81deg'
    },
    {
        name: 'Star'
    },
    {
        name: 'FLY',
        X:'26%',
        Y: '-301%',
        Z: '114deg'
    },
    {
        name: 'Star'
    },
    {
        name: 'DYNOMITE',
        X:'48%',
        Y: '-210%',
        Z: '151deg'
    },
]

const contentList = {
    "COMMON": "The Soul-O-Meter starts with Common dogs which are the top 45% of rarity. These dogs have a mix of base traits but don't let the name fool you, these dogs still got a lot of soul.",
    "COOL": "Cool dogs are the top 30% of rarity and feature mostly common traits with a splash of more rare traits. These dogs are cooler than a polar bear's toenails, icecold!",
    "GROOVY": "Groovy dogs land in the top 15% of rarity. These dogs are mix common, cool, and rare traits and sure know how to get down and groove.",
    "FLY": "Fly dogs are the top 7% in rarity and sport some of the most distinct and iconic traits from 70s, 80s, and 90s pop culture. You can see a Fly dog coming from a mile away! ",
    "DYNOMITE": "The top 3% in rarity, Dynomite! dogs feature 1/1 rare traits making them cooler than cool, more groovy than the groove, and so Fly they might never come down."
}

function Section3() {

    const [angle, setAngle] = React.useState({
        X:'-25%',
        Y: '0%',
        Z: '0deg'
    });

    const [isIos, setIsIos] = React.useState()
    function sleep(milliseconds) {  
        return new Promise(resolve => setTimeout(resolve, milliseconds));  
     }  
     
    useEffect(()=>{
        var userAgent = navigator.userAgent.toLowerCase();
        var Android = userAgent.indexOf("android") > -1;
        var IOS = 
                !!navigator.platform &&
                /iPad|iPhone|iPod/.test(navigator.platform);
        console.log(IOS)
        if (IOS) {
            setIsIos(true)
            const neddle = document.querySelector('.needle')
            // const rotater = document.querySelector('.rotater')
            const round = document.querySelector('.round')

            neddle.style = 'display: none; opacity: 0;'
            round.style = 'display: none; opacity: 0;'
            // rotater.style = 'display: flex;'
        }
    },[])
     
    const findIndexValue = (arr, prodName) => {
        return arr.findIndex((e) => e.name === prodName)
    }
    const updateAngle = async(item) => {
        const neddle = document.querySelector('.needle')
        // const rotater = document.querySelector('.rotater')
        // console.log(neddle)
        const id = findIndexValue(ButtonList, activeButton)
        const currentid = findIndexValue(ButtonList, item)
        setActiveButton(item)

        // const id = ButtonList.filter((data, index)=>{
        //     if (data.name === activeButton) {
        //         return index
        //     }
        // }) 
        // const currentid = ButtonList.filter((data, index)=>{
        //     var i
        //     if (data.name === item) {
        //         i = index
        //         return i
        //     }
        // }) 
        // console.log(id, currentid)
        
        var userAgent = navigator.userAgent.toLowerCase();
        var Android = userAgent.indexOf("android") > -1;
        var IOS = 
        !!navigator.platform &&
        /iPad|iPhone|iPod/.test(navigator.platform);
        if (id < currentid) {
            for (let i = id; i<= ButtonList.length; i++) {
                // console.log(i, item)
                const angles = ButtonList[i]
                // console.log(angles.X, "anglez", ButtonList[i])
                if (ButtonList[i].name !== 'Star') {
                    if (!IOS){
                        neddle.style = `transform: translateX(${angles.X}) translateY(${angles.Y})  rotateZ(${angles.Z})`;
                    }else {
                        const data = newList[item]
                        // rotater.style = `transform: translateX(${data.X}) translateY(${data.Y})  rotateZ(${data.Z}); display: flex;`;

                    }
                    // rotater.style = `transform: translateX(${angles.X}) translateY(${angles.Y})  rotateZ(${angles.Z})`;
                    await sleep(200)
                }
                // angle.X = angles.X
                // angle.Y = angles.Y
                // angle.Z = angles.Z
    
                if (angles.name === item) {
                    break
                }
            }
        } else {
            for (let i = id; i >= 0; i--) {
                // console.log(i)
                const angles = ButtonList[i]
                if (ButtonList[i].name !== 'Star') {
                    if (!IOS) {
                        neddle.style = `transform: translateX(${angles.X}) translateY(${angles.Y})  rotateZ(${angles.Z})`;
                    }
                    else {
                        const data = newList[item]
                        // rotater.style = `transform: translateX(${data.X}) translateY(${data.Y})  rotateZ(${data.Z}); display: flex;`;

                    }
                    // rotater.style = `transform: translateX(${angles.X}) translateY(${angles.Y})  rotateZ(${angles.Z})`;
                    await sleep(200)
                }
                // angle.X = angles.X
                // angle.Y = angles.Y
                // angle.Z = angles.Z
    
                if (angles.name === item) {
                    break
                }  
            } 
        }

    }
    const [activeButton, setActiveButton] = React.useState("COMMON")

    return (
        <Container>
            <Wrap>
                <LeftSection>
                    <LeftWrap>
                        {isIos ? (
                            <img className='counter' src={list[activeButton]} alt="counter" />
                        ):
                        (
                            <img className='counter' src="assests/counter.png" alt="counter" />
                        )
                        }
                        <Rotater angle={angle}>
                            <img className='needle' src="assests/needle.png" alt="rotater" />
                            {/* <img className='rotater' src="assests/rotater.png" alt="rotater" /> */}
                            <img className='round' src="assests/round.png" alt="round" />
                        </Rotater>
                    </LeftWrap>
                    <Text>
                        SOUL-O-METER
                    </Text>
                </LeftSection>
                <RightSection>
                    <RightWrap>
                        <Text>Got Soul?</Text>
                        <p>Every Soul Dogs got soul, but their soul level is measured on the Soul-O-Meter between 3-11+ points. Points are determined by the traits a Soul Dog has - the more rare the traits, the more soul you got!</p>
                        <ButtonController>
                            <ButtonControls>
                                {ButtonList.map((data, index) => (
                                    data.name === "Star" ? (
                                        <Star src='/assests/Vector.png'/>
                                    ):(
                                        <Button 
                                        onClick={()=>{
                                            // setAngle({X:'-16%', Y: '-74%', Z: '49deg'})
                                            // setAngle({X: data.X, Y: data.Y, Z: data.Z})
                                            updateAngle(data.name)
                                        }} 
                                        active={activeButton === data.name}>{data.name}</Button>
                                    )
                                ))}
                            </ButtonControls>
                                <Content>
                                    {contentList[activeButton]}
                                </Content>
                        </ButtonController>
                    </RightWrap>
                </RightSection>
            </Wrap>
        </Container>
    )
}

export default Section3

const Container = styled.div`
    min-height: 100vh;
    /* background-color: #fff9f0; */
    background-image: url('/assests/Section 3.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
`

const Wrap = styled.div`
    padding: 200px 64px;
    display: flex;
    justify-content: space-between;

    @media(max-width: 1200px) {
        flex-direction: column;
        align-items: center;
        padding: 120px 64px;

    }
`

const LeftSection = styled.div`
    width: fit-content;
    display: flex;
    flex-direction: column;
    

`

const LeftWrap = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    .counter {
        max-width: 632.79px;
        max-height: 415px;
        width: 100%;
        height: 100%;
        min-width: 100px;
        min-height: 100px;
        object-fit: contain;
    }
`

const Rotater = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    /* width: 600px;
    height: 600px; */
    img {
        position: absolute;
        /* transform: ${props => `translateX(${props.angle.X}) translateY(${props.angle.Y})  rotateZ(${props.angle.Z})`}; */

        /* width: auto; */
        max-height: 38px;
        height: 100%;
        min-height: 10px;
    }

    .needle {
        transform: translateX(-25%) translateY(-30%)  rotateZ(0deg);
        transform-origin: bottom;
        transition: transform 0.5s;
        margin-right: 60px;

    }

    .rotater {
        /* margin: 0; */
        max-height: 48px;
        margin-right: 40%;
        height: 100%;
        min-height: 10px;
        display: none;
    }

    .round {
        max-height: 40px;
        height: 100%;
        min-height: 10px;
        margin-bottom: 6px;
    }

    @media(max-width: 1400px) {
        img {
            max-height: 36px;
            height: 100%;
            min-height: 10px;
        }
    }
    @media(max-width: 700px) {
        img {
            max-height: 30px;
        }
    }
    @media(max-width: 540px) {
        img {
            max-height: 20px;
        }
        .round {
            max-height: 30px;
            height: 100%;
            min-height: 10px;
            margin-bottom: 6px;
            /* display: none; */
        }
        .needle {
            margin-right: 30px;
            margin-bottom: 6px;
            /* display: none; */
        }
        .rotater {
            max-height: 38px;
        }
    }
    @media(max-width: 450px) {
        .rotater {
            max-height: 28px;
            margin-right: 120px;
        }
    }
`

const Text = styled.div`
    font-family: spicy;
    font-style: normal;
    font-weight: normal;
    font-size: 75.9713px;
    color: #24485B;
    display: flex;
    justify-content: center;
    @media(max-width: 1400px) {
        font-size: 60px;
    }   
    @media(max-width: 540px) {
        font-size: 40px;
    }
    @media(max-width: 400px) {
        font-size: 30px;
    }
`

const RightSection = styled.div`

    margin: 0 16px;

    p {
        margin: 16px 0;
        /* max-width: 750px;
        width: 100%;
        min-width: 160px; */
        /* font-family: Montserrat; */
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 29px;
        text-align: justify;
    }

    @media(max-width: 1400px) {
        p {
            /* max-width: 650px; */
            font-size: 20px;

        }
    }
    @media(max-width: 1200px) {
        margin-top: 16px; 
    }
`

const RightWrap = styled.div`
    max-width: 750px;
    width: 100%;
    min-width: 160px;
    margin: 0 16px;
    @media(max-width: 700px) {
        max-width: 500px;
    }
    @media(max-width: 540px) {
        max-width: 360px;
    }
    @media(max-width: 390px) {
        max-width: 320px;
    }
    @media(max-width: 340px) {
        max-width: 280px;
    }
`

const ButtonController = styled.div`
    /* max-width: 760.43px;
    width: 100%;
    min-width: 100px; */
    background: #24485B;
    border-radius: 24px;

`

const Content = styled.div`
    padding: 16px 24px;
    /* font-family: Montserrat; */
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    text-align: justify;
    color: #FFFFFF;
    @media(max-width: 1400px) {
        font-size: 22px;
    }
    @media(max-width: 700px) {
        font-size: 16px;
    }
    @media(max-width: 540px) {
        font-size: 14px;
    }
`

const ButtonControls = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background: #FFF1DB;
    border: 3.15147px solid #24485B;
    box-sizing: border-box;
    border-radius: 16px;
    padding: 6px 33.6156px;
`

const Button = styled.div`
    font-family: spicy;
    font-style: normal;
    font-weight: normal;
    font-size: 25.2117px;
    /* line-height: 35px; */
    padding: 8px 12px;
    color:  ${props => props.active ? 'white' : '#24485B'};
    background: ${props => props.active ? '#24485B' : ''};
    border-radius: 16px;
    cursor: pointer;
    /* margin: 0px 25.2117px; */
    @media(max-width: 1400px) {
    
        font-size: 22px;
    }

    @media(max-width: 700px) {
        font-size: 16px;
    }
    @media(max-width: 540px) {
        font-size: 14px;
    }
    @media(max-width: 390px) {
        font-size: 12px;
        padding: 8px;  
    }

`

const Star = styled.img`
    @media(max-width: 540px) {
        display: none;
    }
`