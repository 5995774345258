import React from 'react'
import styled from 'styled-components'

function Accordion({title, desc, first, last}) {

    const [active, setActive] = React.useState(false)

    return (
            <Container last={last}>
                <AccordionButton active={active} className={first?'true':!active && last? 'last': '' }>
                    <AccordionButtonWrap active={active} onClick={()=>setActive(!active)}>
                        {title}
                        <span className="icons">{active ? '-': '+'}</span>
                    </AccordionButtonWrap>
                </AccordionButton>
                <AccordionDescription className={last ? 'last': ''} active={active} dangerouslySetInnerHTML={{ __html: desc }}>
                    {/* {desc} */}
                </AccordionDescription>
            </Container>
    )
}

export default Accordion


const Container = styled.div`
    border-bottom: ${props => props.last ? '': '3px solid #24485B'};
    .true {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }
    .last {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
    }
`

const AccordionButton = styled.div`
    /* background: #24485B; */
    background-color: ${props => props.active ? '#24485B' : '#FFF9F0' };
    color: ${props => props.active ? 'white' : '#24485B' };
    /* background: #FFF9F0; */
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    transition: background-color 200ms;
    /* identical to box height */
    @media(max-width: 400px) {
        font-size: 22px;
    }
`

const AccordionButtonWrap = styled.div`
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 16px 12px;
    display: flex;
    cursor: pointer;
    justify-content: space-between;

    .icons {
        font-size: 1.6em;
    }
`

const AccordionDescription = styled.div`
    background: #FFF9F0;
    padding: ${props => props.active ? '16px 12px': '0'};
    /* padding: 16px 12px; */
    border-top: ${props => props.active ? '3px solid #24485B' : '0'};
    /* border-bottom: ${props => props.active ? '3px solid #24485B' : '0'}; */
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    /* line-height: 34px; */
    color: #24485B;
    max-height: ${props => props.active ? '600px' : '0px'};
    transition: max-height 0.4s ease-out;
    /* opacity: ${props => props.active ? '1' : '0'}; */
    overflow: hidden;
    transition: padding 0.4s;
    a {
        color: #249bda;
        text-decoration: none;
        font-weight: 600;
    }
    @media(max-width: 400px) {
        font-size: 20px;
    }
    /* display: ${props => props.active ? 'flex': 'none'}; */
`